import "./App.css";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/footer";
import Home from "./components/home";
import Contactus from "./components/Contactus";
import Skills from "./components/skills";
import SkillItem from "./components/skillItem";
import Cart from "./components/Cart";
import Resetpassword from "./components/ResetPassword";
import Blog from "./components/Blog";
import Blog1 from "./components/Blog/Blog1";
import Blog2 from "./components/Blog/Blog2";
import Blog3 from "./components/Blog/Blog3";
import Aboutus from "./components/Aboutus";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsandConditions";
import ReturnsAndRefunds from "./components/ReturnsAndRefunds";
import Teams from "./components/Teams";
import Careers from "./components/Careers";
import NotFound from "./components/NotFound";
import BackgroundVideo from "./components/backgroundVideo";
import Webinars from "./components/webinars";
import AuthProvider from "./contexts/authContext";
import SkillsProvider from "./contexts/skillsContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./components/protectedRoute";
import SkillCategory from "./components/skillCategory";
import Layout from "./components/layout";

const App = () => {
    const [cartCourses, setCartCourses] = useState([]);
    const addToCart = (course) => {
        setCartCourses([...cartCourses, course]);
    };

    return (
        <AuthProvider>
            <SkillsProvider>
                <BrowserRouter>
                    <ToastContainer />
                    <BackgroundVideo />
                    <Routes>
                        <Route element={<Layout />}>
                            <Route path="/" element={<Home />} />
                            <Route path="/blogs" element={<Blog />} />
                            <Route path="/contact us" element={<Contactus />} />

                            <Route path="/about us" element={<Aboutus />} />
                            <Route
                                path="/privacy policy"
                                element={<PrivacyPolicy />}
                            />
                            <Route
                                path="/terms and conditions"
                                element={<TermsAndConditions />}
                            />
                            <Route
                                path="/refund and cancellation"
                                element={<ReturnsAndRefunds />}
                            />
                            <Route path="/blog1" element={<Blog1 />} />
                            <Route path="/blog2" element={<Blog2 />} />
                            <Route path="/blog3" element={<Blog3 />} />

                            {/* Protected Routes */}
                            <Route element={<ProtectedRoute />}>
                                {/* <Route path="/skills">
                                    <Route index element={<Skills />} />
                                    <Route path=":skillId">
                                        <Route index element={<SkillItem />} />
                                        <Route
                                            path=":categoryId"
                                            element={<SkillCategory />}
                                        />
                                    </Route>
                                </Route> */}

                                <Route
                                    path="/cart"
                                    element={<Cart courses={cartCourses} />}
                                />
                                <Route
                                    path="/resetpassword"
                                    element={<Resetpassword />}
                                />
                                <Route path="/teams" element={<Teams />} />
                                <Route path="/careers" element={<Careers />} />
                                <Route path="products">
                                    <Route
                                        path="webinars"
                                        element={<Webinars />}
                                    />

                                    <Route path="skills">
                                        <Route index element={<Skills />} />
                                        <Route path=":skillId">
                                            <Route
                                                index
                                                element={<SkillItem />}
                                            />
                                            <Route
                                                path=":categoryId"
                                                element={<SkillCategory />}
                                            />
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>

                            <Route
                                path="*"
                                element={<NotFound status={404} />}
                            />
                        </Route>
                    </Routes>
                    <Footer />
                </BrowserRouter>
            </SkillsProvider>
        </AuthProvider>
    );
};

export default App;
